import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Self-signed SSL certificate for the INSTAR MQTT Broker",
  "path": "/Frequently_Asked_Question/INSTAR_MQTT_Self_Signed_SSL_Certificate/",
  "dateChanged": "2020-02-26",
  "author": "Mike Polinowski",
  "excerpt": "How do I create a custom SSL certificate for the INSTAR MQTT Broker under Windows 10?",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - Self-signed SSL certificate for the INSTAR MQTT Broker' dateChanged='2020-02-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='How do I create a custom SSL certificate for the INSTAR MQTT Broker under Windows 10?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/INSTAR_MQTT_Self_Signed_SSL_Certificate/' locationFR='/fr/Frequently_Asked_Question/INSTAR_MQTT_Self_Signed_SSL_Certificate/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "self-signed-ssl-certificate-for-the-instar-mqtt-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#self-signed-ssl-certificate-for-the-instar-mqtt-broker",
        "aria-label": "self signed ssl certificate for the instar mqtt broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Self-signed SSL certificate for the INSTAR MQTT Broker`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openssl"
        }}>{`OpenSSL`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#broker-certificate-and-client-public-key"
        }}>{`Broker Certificate and Client Public Key`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#brokerpem"
            }}>{`broker.pem`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#clientpem"
            }}>{`client.pem`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-the-certificate-to-your-cameras"
        }}>{`Adding the Certificate to your Cameras`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#testing-the-mqtt-network"
        }}>{`Testing the MQTT Network`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#mqttfx"
            }}>{`MQTT.fx`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <p><strong parentName="p">{`Q`}</strong>{`: How do I create a custom SSL certificate for the INSTAR MQTT Broker under Windows 10?`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`A`}</strong>{`: To create a self-signed certificate on Windows 10 we first have to install OpenSSL.`}</p>
    <h2 {...{
      "id": "openssl",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openssl",
        "aria-label": "openssl permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenSSL`}</h2>
    <p>{`You can download a Win32 distribution of `}<a parentName="p" {...{
        "href": "http://www.slproweb.com/products/Win32OpenSSL.html"
      }}>{`OpenSSL here`}</a>{`. You might also need C++ re-distributable files if you want to use OpenSSL which can be obtained at `}<a parentName="p" {...{
        "href": "http://www.microsoft.com/downloads/details.aspx?FamilyID=9B2DA534-3E03-4391-8A4D-074B9F2BC1BF&displaylang=en"
      }}>{`this link`}</a>{`.`}</p>
    <p>{`Now open a command prompt `}<strong parentName="p">{`as ADMINISTRATOR`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "821px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a70465c16756da7fb1a5127c4436a4c2/02cd5/Windows-Self-Signed-Cert_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACiElEQVQoz32QXW/SUBjH+zHUAAW/g3ppjIkzcX4oE3c1CNPAYG4mRO+82dW2bBov1BUCzhYWBvQwwELHW097TmFtT9+OadGF6OYv/5vz5PzyvDAnvJDNF9Ibr7fyuZ3tN/lcdmsrl89t5jYz2Ww2nX6VTKWSyeTGxkahUMhkMuvr66lUKp1OJ5NJ5gx03384/Midc9XxsTCsNDQezHgwqzRn+qVLKfV9n1KKEHq5tiYIAqXU8zwawjRBL/9uf/fT2d7Xzt6X80NOOioNjoqD/W/94VSn1LNtm1La7XZXVp4eHBxQSgkhrusSQhhRbPd6ElQDIITTqYIxQgipqmpZ1qKD4zi6rlNK5/O5YRiLouu6TFMEPM83Gs1WS2wFiP3+AGOsQHglL75ijAkhyxVGbJ9LkhRIoggAkGX54uJiMYVpWSaxLcu8Ehb7+yGO4zBCrd5ug26ILMuDwUCSpNFohBDSEO79HLYBaHc7/UYDv1iDnQ7ECCoBmqYxjWZLlgcAgOFwqGkaxljXdYyxqkLDNPHMnEwmo/H4Utc9eejZtvcH27aZk5PvAASdJyFaSHAwCC1C5gaZTqfz+Zz+Q7BzkePG4zGEECGEF4cO0TTNsizfp1bI9TLP8zrWl7Vlmd5MIAPQ0jHWVPUm2fdpeOO/cVyXqTfq2mwGEYJQWY6iKIZx6fue57nXxrYJUxcEW1HIVDFN2ySOaYUhjmE6nuf/Z2zPp8zu9g6Xe1va3K6Uf3CV01KQWrFcLVVqpd/P02K5xpWrXLlaDFLjyrVi5fTzcY15vrp6/96Dh49WHj95dufW7UQ8EWfZeIxNxBMJNh5n4wk2wcZYNsbGItFYJMpGY7FINBpho+zdX/sjMLzov3EOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a70465c16756da7fb1a5127c4436a4c2/e4706/Windows-Self-Signed-Cert_01.avif 230w", "/en/static/a70465c16756da7fb1a5127c4436a4c2/d1af7/Windows-Self-Signed-Cert_01.avif 460w", "/en/static/a70465c16756da7fb1a5127c4436a4c2/d02b5/Windows-Self-Signed-Cert_01.avif 821w"],
              "sizes": "(max-width: 821px) 100vw, 821px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a70465c16756da7fb1a5127c4436a4c2/a0b58/Windows-Self-Signed-Cert_01.webp 230w", "/en/static/a70465c16756da7fb1a5127c4436a4c2/bc10c/Windows-Self-Signed-Cert_01.webp 460w", "/en/static/a70465c16756da7fb1a5127c4436a4c2/d77d0/Windows-Self-Signed-Cert_01.webp 821w"],
              "sizes": "(max-width: 821px) 100vw, 821px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a70465c16756da7fb1a5127c4436a4c2/81c8e/Windows-Self-Signed-Cert_01.png 230w", "/en/static/a70465c16756da7fb1a5127c4436a4c2/08a84/Windows-Self-Signed-Cert_01.png 460w", "/en/static/a70465c16756da7fb1a5127c4436a4c2/02cd5/Windows-Self-Signed-Cert_01.png 821w"],
              "sizes": "(max-width: 821px) 100vw, 821px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a70465c16756da7fb1a5127c4436a4c2/02cd5/Windows-Self-Signed-Cert_01.png",
              "alt": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "title": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And change into the OpenSSL\\bin directory - e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cd \\Program Files\\OpenSSL-Win64\\bin`}</code>{` for the 64bit version of OpenSSL. Here we can now use the OpenSSL command to create a certificate for our camera - `}<strong parentName="p">{`Note`}</strong>{` that you have to add your camera's IP address (or domain - if you have those assigned inside your local network) to this command.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl req -x509 -nodes -subj `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/CN=192.168.2.117"`}</span>{` -newkey rsa:2048 -keyout private-key.pem -out cert.pem -days `}<span parentName="code" {...{
            "className": "token number"
          }}>{`365`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3de99751e524a645f803f28909249531/d30ee/Windows-Self-Signed-Cert_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.478260869565215%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAArElEQVQY04XLsQqDMBgE4IAdmkGaiH+qolgwHQJWjC4+gKYKJsbF93+UYsSx7Tccd8MhKWXbtrKum6apqqosX0IIznlRFPy7p4Oi6A4h7MIwiuM4SYCxEMD3fc/zLj+haRyHYVBKjeMkhOi6bts2a+26rsuyGLPo3WytNY7Wsz4Yg45n3/dvpTjnDNjDyfM8c9I0zU7pOY9EtxuhlBJCMMZBEAAApXvHGF//+QB7MyjLmFXWCwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3de99751e524a645f803f28909249531/e4706/Windows-Self-Signed-Cert_02.avif 230w", "/en/static/3de99751e524a645f803f28909249531/d1af7/Windows-Self-Signed-Cert_02.avif 460w", "/en/static/3de99751e524a645f803f28909249531/7f308/Windows-Self-Signed-Cert_02.avif 920w", "/en/static/3de99751e524a645f803f28909249531/b84c9/Windows-Self-Signed-Cert_02.avif 980w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3de99751e524a645f803f28909249531/a0b58/Windows-Self-Signed-Cert_02.webp 230w", "/en/static/3de99751e524a645f803f28909249531/bc10c/Windows-Self-Signed-Cert_02.webp 460w", "/en/static/3de99751e524a645f803f28909249531/966d8/Windows-Self-Signed-Cert_02.webp 920w", "/en/static/3de99751e524a645f803f28909249531/58b01/Windows-Self-Signed-Cert_02.webp 980w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3de99751e524a645f803f28909249531/81c8e/Windows-Self-Signed-Cert_02.png 230w", "/en/static/3de99751e524a645f803f28909249531/08a84/Windows-Self-Signed-Cert_02.png 460w", "/en/static/3de99751e524a645f803f28909249531/c0255/Windows-Self-Signed-Cert_02.png 920w", "/en/static/3de99751e524a645f803f28909249531/d30ee/Windows-Self-Signed-Cert_02.png 980w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3de99751e524a645f803f28909249531/c0255/Windows-Self-Signed-Cert_02.png",
              "alt": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "title": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This command creates a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`private`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`public key`}</code>{` (certificate) for the camera with the IP address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.117`}</code>{` - this is the IP address of the camera that we want to use as our MQTT Broker. Please change this IP address to the IP of your own camera. You will find the two files inside the OpenSSL `}<em parentName="p">{`/bin`}</em>{` directory - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`private-key.pem`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cert.pem`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/79202770fe6b15bd6c994970e29ce297/e548f/Windows-Self-Signed-Cert_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABVElEQVQY023QTU+DQBAGYD5qmtjEgyERlgUWlqUobYEiK7DQpZVaS9uYKqf6//+HoZ4wPqc3M5nMZARafxAXTSaT8Xgsy7IoipIkiaIoS30WhsQrSZJuRqPbu3tBsxOE0JNvI9tBCLkudnvY8whCSFVVcKVpGgAAQqjrunoFTSTA6SyMYwhhmizCeQAh9P3H5zQlruv7PiHEsizHceyhvoIswQ0WcbxUH9T3/e54PARBkBfFft+WjJVlWdd1HEeU0iiKTNNEQwJNw8V8BgDYvr11X11VVbyu2/bQB85Pp1NR5FVVJcnSMAwb2YPhOWVhFBkGpC9ZluUeIRjjMIwwxoR4Udi3MMa+P7Us6+9mDQBd1xVFWfG667osyxhjbduuqmrFebtv8zxnBVvG8T9n/75RUZTdbne5fJeMbTab8/lz27w2TbNerymlnPMkSQzDQMOzfwBQDkj6YD5IKgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/79202770fe6b15bd6c994970e29ce297/e4706/Windows-Self-Signed-Cert_03.avif 230w", "/en/static/79202770fe6b15bd6c994970e29ce297/d1af7/Windows-Self-Signed-Cert_03.avif 460w", "/en/static/79202770fe6b15bd6c994970e29ce297/7f308/Windows-Self-Signed-Cert_03.avif 920w", "/en/static/79202770fe6b15bd6c994970e29ce297/6d24a/Windows-Self-Signed-Cert_03.avif 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/79202770fe6b15bd6c994970e29ce297/a0b58/Windows-Self-Signed-Cert_03.webp 230w", "/en/static/79202770fe6b15bd6c994970e29ce297/bc10c/Windows-Self-Signed-Cert_03.webp 460w", "/en/static/79202770fe6b15bd6c994970e29ce297/966d8/Windows-Self-Signed-Cert_03.webp 920w", "/en/static/79202770fe6b15bd6c994970e29ce297/1e975/Windows-Self-Signed-Cert_03.webp 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/79202770fe6b15bd6c994970e29ce297/81c8e/Windows-Self-Signed-Cert_03.png 230w", "/en/static/79202770fe6b15bd6c994970e29ce297/08a84/Windows-Self-Signed-Cert_03.png 460w", "/en/static/79202770fe6b15bd6c994970e29ce297/c0255/Windows-Self-Signed-Cert_03.png 920w", "/en/static/79202770fe6b15bd6c994970e29ce297/e548f/Windows-Self-Signed-Cert_03.png 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/79202770fe6b15bd6c994970e29ce297/c0255/Windows-Self-Signed-Cert_03.png",
              "alt": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "title": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "broker-certificate-and-client-public-key",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#broker-certificate-and-client-public-key",
        "aria-label": "broker certificate and client public key permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Broker Certificate and Client Public Key`}</h2>
    <p>{`Our broker camera - in my case the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.117`}</code>{` will need both - the private and the public key. Open both files and copy the content of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`private-key.pem`}</code>{` at the end of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cert.pem`}</code>{`. Save this new file with the name `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`broker.pem`}</code>{`. The result will look something like this:`}</p>
    <h3 {...{
      "id": "brokerpem",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#brokerpem",
        "aria-label": "brokerpem permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`broker.pem`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`-----BEGIN PRIVATE KEY-----
vhjk78696hkgkfkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDPkmJujI3pia4z
+FeARMlURp/7oLyXXeV/qxWvVTdCXCX0QSGKvv0fopoRkzAmL3LCVJVsyv+meuhZ
4xW/cbt62Qvp7zI2fvJedUP42VJNzjuRRxRURcoD6/vm5E9hgrjKhO2U1xhRLgPS
xUSW/0MvjIs3eDwYMa3vRBp4DfhNnbKF7lOiO+7vajEK3vt9yzeghLlnYpDj6vCZ
AxQuP7Bv7Dgdfgfdsrtyu568fghj8gyouiuluiygfEJzOecoyTkx+6Y6MJ+7rB1t
pRk/je7zfUmthZ/0MhuBo9S6wAPGFNbP+nXE8bxx//BAcfrV9lSDU1NkG4iCgRxD
PRlPOfi3AgMBAAECggEAe1eR0/0DStW8RTd5z0eQltqMsDo4Nn3eJLnl3dT2TCFz
kCuvocGRqd22TWE4CEjDmYQncolB1oxMSzVsM+GCNSKKOM0g+k7++HAYHuQMSx2U
OeSRyO3bd+8fNXlPTjMeWYEZqAsUcRC1xuXftxYhtzx9cU2p7/p9T6MZU2OBu6Q5
4WIlFnsGr3Y3YXHZHjzndXwf2JR8zL94H2QamoA7JsnUxtWp2FZot4hSdQuuE8Fw
5ahYC7pW9pjTg9i+STAxDUCpt9ggb23AZMufRPYNAGJ6ckGb4LMAr2bxofEFgNde
ZBhRVssjdtykjd68r6tjkfkyuir67i8tkgdjd6wV8QKBgQD2fACkAYU9VOsbjruw
k/Uv1row5AoqIgGroUL/F4cr6mq0L5EMKDB5uK+FglU5DiKKkp7ESYFDlkrV4Bxs
1bmVIcBn3453456dfh456fghgfjW8AIHiC98u5wtcya9BMiuU3UACRYv++lBqJ1S
p/2RTlVwaThhsxbOw7+xPlJuOQKBgQDXlc9uRJG3Lm6NVRu1ngjSJpn9pTerG/7d
abyqAW+p3CyAPOkmlOBHBfXZ6P52yAnMJ8y18x+nd2OT66RATHv3q+fD0Kz4N8pL
1k05Lsqxf4U7W1ySrs6756dgkyukirf657edcjudi0FB1xuMQVzKBnerUumST1U+
jtOhVR2ebwKBgGDhJmNQEa2klfAz7sOdtA5EiNQ0nWYoDZ4G+U1IUyHItn3yAEHV
/g+prpt8OcD+NEdhD7Q46+zDwtBDkQiaDKJ4LKbVff/FOdwxANRNHZs3KTSvSyzo
szmEiAM5Mm2y1qnmNaN47taXXfwnqQZW7wxQzLYtNTL5cCp+t/aTd1TJAoGAPrb1
DfXikfBu9qxwkCf2FvRlzbTXee59+Pk+ihI64qYLSA7wIGokyGSCfa7a/8D3oK1f
Tvr4FWaU0D43nQDisrtu678rfyukjgt678FJM9ON9ZOBXaZcYnUhJb3tOirB26/5
F/HNRN+7zyR9fhKxpI+3Fl7bVdXATZ+NDxzMrf8CgYEA4jJdX0qum/EI4UTxPXhs
e301ki4p2Xad4mFnQWl3hPQYROcgMJoOl1nGokLK1fqJZRJPysG0IrX91PgZsHas
oCMiAzwFp6yByCP2VtfM3bSmHx4QKHFvPkn7srVHKti5B8j2E7KnF1txEa0Fj5Mx
gDSkEBWSsrtyu5678stshyjtyjftykfuky678rluxdtas34568rykgfhjkkxfdxj
-----END PRIVATE KEY-----
-----BEGIN CERTIFICATE-----
dhxhxrth567edjytjdk6TZahDpmPZIwE9cq+C7C0unBj1AEwDQYJKoZIhvcNAQEL
BQAwGDEsr67y54e6dftyMTkyLjE2OC4yLjExNzAeFw0yMDAyMjgwOTIyNDRaFw0y
MTAyMjcwOsrhtdnt678r6ikjBgNVBAMMDTE5Mi4xNjguMi4xMTcwggEiMA0GCSqG
SIb3DQEBAQUAA4IBDwAwggEKAoIBAQDPkmJujI3pia4z+FeARMlURp/7oLyXXeV/
qxWvVTdCXCX0QSGKvv0fopoRkzAmL3LCVJVsyv+meuhZ4xW/cbt62Qvp7zI2fvJe
dUP42VJNzjuaert5476sfhjdtyjdtyjKhO2U1xhRLgPSxUSW/0MvjIs3eDwYMa3v
RBp4DfhNnbKF7lOisrtu658ughjfvyukd5dxftjj6vCZAxQuP7Bv7Dg/WFrJHDK2
dkP06mImwCV415LZagM0cEJzOecoyTkx+6Y6MJ+7rB1tpRk/je7zfUmthZ/0MhuB
o9S6wAPGFNbP+nXE8bxx//BAcfrV9lSDU1NkG4iCgRxDPRlPOfi3AgMBAAGjUzBR
MB0GA1UdDgQW45srthyazregt45yfxghtyj6jdyWgTAfBgNVHSMEGDAWgBQKW176
GE+v0qlcImNqu0mGmdyWgTAPBgNVHRMBAf8EBTADAQH/MA0GCSqGSIb3DQEBCwUA
A4IBAQA8/wmb+Sxdry65uvhjkhvztz4ebthxhjjSIl1J27X8rOKRNofLX3BRtyX9
mHLXHyYhuqaCAwGDohGoSqIkWejL4g+qQXZifW5We/MTByoWtB7aqKt3Wz0z8+TI
x/AkEo9bssIiNkEvUUlxQyFd7K/klHHw/dh3un3mGu2XrlzmbTqUlastW6n3bO11
UUS8aYC3L37VbjZQxug1ru9/I1j8I+mU272CEoI/eAXHyBd+GoZzREnxMyPzct7i
FHw7UMn2V6dJBtfZjOfqqX+xNTtZ9kQDaWO5s/JpRYX8hlfCKGD871J5ba4VfK1+
/rHJkgQLZaLk9Ysdtyu5678rdftjgyu9i87lhiolt67d56cyti67vhky7
-----END CERTIFICATE-----`}</code></pre></div>
    <h3 {...{
      "id": "clientpem",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#clientpem",
        "aria-label": "clientpem permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`client.pem`}</h3>
    <p>{`Every client you want to connect to your broker - e.g. a second camera - will only need the public key from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cert.pem`}</code>{`. Open this file and save it as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`client.pem`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`-----BEGIN CERTIFICATE-----
dhxhxrth567edjytjdk6TZahDpmPZIwE9cq+C7C0unBj1AEwDQYJKoZIhvcNAQEL
BQAwGDEsr67y54e6dftyMTkyLjE2OC4yLjExNzAeFw0yMDAyMjgwOTIyNDRaFw0y
MTAyMjcwOsrhtdnt678r6ikjBgNVBAMMDTE5Mi4xNjguMi4xMTcwggEiMA0GCSqG
SIb3DQEBAQUAA4IBDwAwggEKAoIBAQDPkmJujI3pia4z+FeARMlURp/7oLyXXeV/
qxWvVTdCXCX0QSGKvv0fopoRkzAmL3LCVJVsyv+meuhZ4xW/cbt62Qvp7zI2fvJe
dUP42VJNzjuaert5476sfhjdtyjdtyjKhO2U1xhRLgPSxUSW/0MvjIs3eDwYMa3v
RBp4DfhNnbKF7lOisrtu658ughjfvyukd5dxftjj6vCZAxQuP7Bv7Dg/WFrJHDK2
dkP06mImwCV415LZagM0cEJzOecoyTkx+6Y6MJ+7rB1tpRk/je7zfUmthZ/0MhuB
o9S6wAPGFNbP+nXE8bxx//BAcfrV9lSDU1NkG4iCgRxDPRlPOfi3AgMBAAGjUzBR
MB0GA1UdDgQW45srthyazregt45yfxghtyj6jdyWgTAfBgNVHSMEGDAWgBQKW176
GE+v0qlcImNqu0mGmdyWgTAPBgNVHRMBAf8EBTADAQH/MA0GCSqGSIb3DQEBCwUA
A4IBAQA8/wmb+Sxdry65uvhjkhvztz4ebthxhjjSIl1J27X8rOKRNofLX3BRtyX9
mHLXHyYhuqaCAwGDohGoSqIkWejL4g+qQXZifW5We/MTByoWtB7aqKt3Wz0z8+TI
x/AkEo9bssIiNkEvUUlxQyFd7K/klHHw/dh3un3mGu2XrlzmbTqUlastW6n3bO11
UUS8aYC3L37VbjZQxug1ru9/I1j8I+mU272CEoI/eAXHyBd+GoZzREnxMyPzct7i
FHw7UMn2V6dJBtfZjOfqqX+xNTtZ9kQDaWO5s/JpRYX8hlfCKGD871J5ba4VfK1+
/rHJkgQLZaLk9Ysdtyu5678rdftjgyu9i87lhiolt67d56cyti67vhky7
-----END CERTIFICATE-----`}</code></pre></div>
    <h2 {...{
      "id": "adding-the-certificate-to-your-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-the-certificate-to-your-cameras",
        "aria-label": "adding the certificate to your cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding the Certificate to your Cameras`}</h2>
    <p>{`Now upload the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`broker.pem`}</code>{` file to your broker camera via the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/SSL_Certificate/"
      }}>{`SSL Menu`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "875px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/62c5b34818d90f98e5527ffd54a21a53/71c8e/Windows-Self-Signed-Cert_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACUElEQVQoz2WST2vUQByG81nEbjKZ2W0VixREETa7ySSbzL8kM/m7u822ai89tO623UpbEATxIKLtiqjgd7B604se/FKSdIWCD+9hLs/M7/cy2urqaqfTgRC2222EUGvlJjv4Er34QU6/+aff+/NL+/ha5pe9o6/+yWXy8qe7+15DsMaE0GxotVbEk7PRs4/l4UVxuFDTczW9UE/PZZNktlDTRX64qE4+BZNjDZhmG8JbCLUhBADouu45vXGZjnI1LpJxocaFqobpuEg2y7RQYa5EJoUUxLYeah0DdE14z4Q9iO4AcFPXB76fF2Wa5U2KpDlEsRRhRCgLCGNcxFI52NXWDGCZcAOiLgDrjey5rpQybBBChCKMwohzzhjjDUIIKWWv19MMANYgXIfwtmmazdgYY6VUWZZFWQyLYTyJSUz4UuSUUkKIEMKyLA0AULeFkFG7SzlN0yzLhBBxFItYUFYLtEEplaaplNK27Vpu+q7bAgAYuu64WOXJeLKplLwSpJRFUWRpWmbZcDgqhyOVpBjjpdyMDEwAVgxD9p1dEu24LAoYE8slwzDkYciiSHDCiRcy3+73NfgPs7ngRsvYZvavE/7njLza8d2ACc4YpR4XW57/W2/v5Pvx/HM2+9D3mIZQ/U0QQlePt1o6DfBsmx49Ch7nhFBWl8w4IQPpWM837kZyZFd7g2rPwt5Svr4zdr0BjbAvfCqupuY0CCdTNXuHZ2/3mXzj2K9dj1rWf7JhdLtd7LqOgx0HN7jY7rtxxbbmXnVQOeTAdma227//4C9ocexNml3nDQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62c5b34818d90f98e5527ffd54a21a53/e4706/Windows-Self-Signed-Cert_04.avif 230w", "/en/static/62c5b34818d90f98e5527ffd54a21a53/d1af7/Windows-Self-Signed-Cert_04.avif 460w", "/en/static/62c5b34818d90f98e5527ffd54a21a53/c963a/Windows-Self-Signed-Cert_04.avif 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/62c5b34818d90f98e5527ffd54a21a53/a0b58/Windows-Self-Signed-Cert_04.webp 230w", "/en/static/62c5b34818d90f98e5527ffd54a21a53/bc10c/Windows-Self-Signed-Cert_04.webp 460w", "/en/static/62c5b34818d90f98e5527ffd54a21a53/a1ca4/Windows-Self-Signed-Cert_04.webp 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62c5b34818d90f98e5527ffd54a21a53/81c8e/Windows-Self-Signed-Cert_04.png 230w", "/en/static/62c5b34818d90f98e5527ffd54a21a53/08a84/Windows-Self-Signed-Cert_04.png 460w", "/en/static/62c5b34818d90f98e5527ffd54a21a53/71c8e/Windows-Self-Signed-Cert_04.png 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/62c5b34818d90f98e5527ffd54a21a53/71c8e/Windows-Self-Signed-Cert_04.png",
              "alt": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "title": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now make sure that the custom certificate is activated and restart your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "917px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/74725512f8c883f3e6599f0b0719e085/59000/Windows-Self-Signed-Cert_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACnUlEQVQoz22Ry08TURSHu1MTXBiSmqK0tIW2M9OpESJtbYN02vJoSzv3MXPvnTsvhopgjBKJr7AwoJGNj0QNC01cuReIK/4D/yszM20Jxi8nN3dxv5z7OydSKBRkWc5lZ2pkp7P7o/noqP7waGHzS1Bfhxe/lK3P6NXPlv08dj06ORmfmkpGZFnOy3I2M929/7r//sx6e+wcnpKDY3JwcqH2T5x3p5sfz1asZ9OphCAImUwmIoqiJEmiKCqLC47JXIsNTk5sTkanwYlJIVpb7nXbAPZareVKuRQRBCGfz4ui2Gg0bNu2LMsMQAhDhCD0S0Wo3+ny/kv0YA9AgDDodtvVSnkgC4JQr9cZY5qm6QFwCIKwh9DOant9ax89Pmx32ksrjaXlRrF4x5clSRIEQVEUSqnjOLZtO47DOffNAIxQB6Pu2qqmtu8tLohStlSen5u7PZD9zIHMGMMYa5qGEIIAjPozQihlhLJSeT4Wi+ZlsXBLPv92rVYzTdPzvMFzAAkzLMcxTdO27X6/77ouY0a5VIxGx+PxG6l0ciDncjlFUXRdRwjpuq5hDHVsIZUDiDSMMQYAUEo557Nzs5evXBq7OjY+fi0iBYQDo5S6rmvbtue42qa1t9Z82lztYOzPrNfb3t72PK9YLE5MxG5O+kTyAWFmwzA45yCMCoAaLAkAoKoqD9B1vVqtJBKJdDqdSqV8Oexcq9U45xsbG/6ohkuCABBCOOfrAZTSSqUSj8dTqVQymRx0zuVyoex5XphwMDUARqbruv/KYeZQ1ikzLQdp+mjD/pIxhhAajNqWSYn+f7muKDbDJgFMO1/vCIAJ1A2NmhdkQRBEUZjJZFvNxd8HvT8f1G+7nU4PDoOHqoqffDLf/CIvvt+tVEfyXxtBF7JA+1MhAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/74725512f8c883f3e6599f0b0719e085/e4706/Windows-Self-Signed-Cert_05.avif 230w", "/en/static/74725512f8c883f3e6599f0b0719e085/d1af7/Windows-Self-Signed-Cert_05.avif 460w", "/en/static/74725512f8c883f3e6599f0b0719e085/bea5c/Windows-Self-Signed-Cert_05.avif 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/74725512f8c883f3e6599f0b0719e085/a0b58/Windows-Self-Signed-Cert_05.webp 230w", "/en/static/74725512f8c883f3e6599f0b0719e085/bc10c/Windows-Self-Signed-Cert_05.webp 460w", "/en/static/74725512f8c883f3e6599f0b0719e085/f91b9/Windows-Self-Signed-Cert_05.webp 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/74725512f8c883f3e6599f0b0719e085/81c8e/Windows-Self-Signed-Cert_05.png 230w", "/en/static/74725512f8c883f3e6599f0b0719e085/08a84/Windows-Self-Signed-Cert_05.png 460w", "/en/static/74725512f8c883f3e6599f0b0719e085/59000/Windows-Self-Signed-Cert_05.png 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/74725512f8c883f3e6599f0b0719e085/59000/Windows-Self-Signed-Cert_05.png",
              "alt": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "title": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Switch over to the camera you want to connect to your broker as a client and upload the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`client.pem`}</code>{` file via the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`MQTT Menu`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "883px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b675856535654843f79512b8b1201116/fe9f1/Windows-Self-Signed-Cert_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACL0lEQVQoz22Sy27TQBSG/S4otWfGKgtQ2zRt3Itjz0zizM2O7YztpEokBG1FRIuQUIVExCJcylWwZkNfA3UHSLwTiq2aIvrpaHaf5pz/HGN1ddW2bYSQXQIscyua7U0W28W8ld9Qm/m8Xczd6WIremTUcsVK45Z68nXy6Ve8+D54efl/RYvL7Pzy/pefydmFgRCCEFYvAMA0Tex3Mp2mcSRYwPs9wYJ/q8cDwvU93GVGZVZtQwhN06SUjkYjvWQYx7EQQkoprsP7anxEA2kAAGyE7JtlnaaplFIpRSn1fd913XaJ4zie11nKECGIEICwlvM811onSSJLlFKEEMdxmle0Npuuu2/YAOxAiAFah8gsZUJIlmVJksRxrJSqfMYYpRSX+L5HmfJ8bNwGoAMgAahpgRUIrPLn8Xg8HA6raSuEEIwxvkRwxuRwinu8bLsMzQIAlmlTSouiGAwGtVy2jXd3nFaz2Vpf21i709q46+7vGXXUCKFqVYSQNEmiMFxqYbhUlfS7/bbb3fbIbpjRaNKLpx6mRn0h9Z59SpOiSPJcZ5nWOorCPpezA3F+Gr1J/Ll+Ov78++D9D09oo75NWNoNywo9/3GczqRKw5ALoaQIuHo2wRdn5Nug9XYL905es9krt8v//gzKmRuWFXU6p0I97AUDzkU1sujT4oQefnBnHx8o/a7jnmMs3H3jes8ALNP2MGZhGEh5lbWSnKniMD1+ER7Oj0Q6D/jzvhIe/gN1tPOeFF1z6AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b675856535654843f79512b8b1201116/e4706/Windows-Self-Signed-Cert_06.avif 230w", "/en/static/b675856535654843f79512b8b1201116/d1af7/Windows-Self-Signed-Cert_06.avif 460w", "/en/static/b675856535654843f79512b8b1201116/ba3b8/Windows-Self-Signed-Cert_06.avif 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b675856535654843f79512b8b1201116/a0b58/Windows-Self-Signed-Cert_06.webp 230w", "/en/static/b675856535654843f79512b8b1201116/bc10c/Windows-Self-Signed-Cert_06.webp 460w", "/en/static/b675856535654843f79512b8b1201116/d4bc4/Windows-Self-Signed-Cert_06.webp 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b675856535654843f79512b8b1201116/81c8e/Windows-Self-Signed-Cert_06.png 230w", "/en/static/b675856535654843f79512b8b1201116/08a84/Windows-Self-Signed-Cert_06.png 460w", "/en/static/b675856535654843f79512b8b1201116/fe9f1/Windows-Self-Signed-Cert_06.png 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b675856535654843f79512b8b1201116/fe9f1/Windows-Self-Signed-Cert_06.png",
              "alt": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "title": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`MQTT configuration`}</a>{` of your two cameras should now look like this (substitute the IP addresses with the IPs of your cameras):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/87050b614181e245e763ed73dddb89cb/12bff/Windows-Self-Signed-Cert_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACv0lEQVQoz13QS28TVxQH8PkUbJAKNhYCd4+q2PU44zue+5qZO/c1M3cedkKcEhNIlEakUZFjYosACx6lVatWVaWuuukKIUAiPNQvVnksEPBbHemcv3TOscj3f6mTd+Hs1D8+9Y9f0zuv+7dfkMmL4uF/cPyI7P3JT96z+Rs2fxvM3oTzt+joFT56md4/VbPnVpCNy507avNwdOv+5g8Ptn58uDP9ubw5jW/M/fw6K8bl7kyNDoud4+u3H1/dv3dz+jTbnuitid46sggEeSKNikZr+SCL14p0Y5jryM/zAmMSEDjM4zwR66UZDfM8kesDoyPCdRYlpUV9/+rGqBwMs7yQSkdcRFwIpY0xhFLqBzpOhFQhiyDCwO33gNv3oIdwyLiFMVZKBUGAEIIQogqEEGPsAAARihiDEK5WnMqyJoRYy7lexf6g0+k41ShCiBACKt3PYYQsQkhRFFLKMAzZR2HA0zXgUUrwYDCI4/iLLtNZqPJFOE1TjLHnefADDyLisx5wKSXGGErpp12IEMQ+DZjleR4hxHXdau2u3e3adsduXXHslZ6z6nmLowAAjuMs7ll0bbv9jd26gqG3eJjWWimltU600tzn6TDZ/y3d/wWgECMvSRK9JLmOqBBC7j6JD/4I0tFi7TzPTZoKqZ7uoJcTZ7ZX0tkzMfvXwZxiWJZlZkwk9PGYvzrhvx4kyeQfM3/GihuLsFJKSimEiDf20t27g3I8pf4R4z4AiJCyLI0xcRxnxpRFYdLEpMn42nc8iiwAAKWUEEIwptk23ZxKsX7Ycw/6uP9tB1ZHyYoQXHAmOGNS6iwLGbOazebljy7WL9bOfnXu7JkL9TO1Wr3R8CldLiWiUI1u5ZO/8+27P/nR74HYpMHn4ebXly416/X6hVqtfv58o9Fot9vd5Yftju24HdfvrPZhq0VabXtl5X/v1yXHrXRrFAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87050b614181e245e763ed73dddb89cb/e4706/Windows-Self-Signed-Cert_07.avif 230w", "/en/static/87050b614181e245e763ed73dddb89cb/d1af7/Windows-Self-Signed-Cert_07.avif 460w", "/en/static/87050b614181e245e763ed73dddb89cb/7f308/Windows-Self-Signed-Cert_07.avif 920w", "/en/static/87050b614181e245e763ed73dddb89cb/4416e/Windows-Self-Signed-Cert_07.avif 1032w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/87050b614181e245e763ed73dddb89cb/a0b58/Windows-Self-Signed-Cert_07.webp 230w", "/en/static/87050b614181e245e763ed73dddb89cb/bc10c/Windows-Self-Signed-Cert_07.webp 460w", "/en/static/87050b614181e245e763ed73dddb89cb/966d8/Windows-Self-Signed-Cert_07.webp 920w", "/en/static/87050b614181e245e763ed73dddb89cb/6ed01/Windows-Self-Signed-Cert_07.webp 1032w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87050b614181e245e763ed73dddb89cb/81c8e/Windows-Self-Signed-Cert_07.png 230w", "/en/static/87050b614181e245e763ed73dddb89cb/08a84/Windows-Self-Signed-Cert_07.png 460w", "/en/static/87050b614181e245e763ed73dddb89cb/c0255/Windows-Self-Signed-Cert_07.png 920w", "/en/static/87050b614181e245e763ed73dddb89cb/12bff/Windows-Self-Signed-Cert_07.png 1032w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/87050b614181e245e763ed73dddb89cb/c0255/Windows-Self-Signed-Cert_07.png",
              "alt": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "title": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now continue adding all your remaining INSTAR Full HD cameras to your broker camera the same way.`}</p>
    <h2 {...{
      "id": "testing-the-mqtt-network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#testing-the-mqtt-network",
        "aria-label": "testing the mqtt network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing the MQTT Network`}</h2>
    <p>{`To check if the connection was successful you can check the MQTT log on each camera under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/tmpfs/mqtt-log`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "718px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e65017da0a532559c8e6883b60a61e9/57dc1/Windows-Self-Signed-Cert_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABFUlEQVQY033OwU7CMBzH8b0eB+OEQBZDgtx8B496MfENfAeuRowvoAdYggaQbS3tGNtiO/rvurUbw4SLetBP8j3/ftZpp3tid5z+4GH8PHtfTNzZxH2bTt3R+PXy9ml483g/esEYXV3ftc96vbbt9AfnF4OWY7e6tuV7H8vVaj6fL5bLIAgIoXGcAICUUua5zJXMc5BSAAghBHwTABYlyMfI8z2MUJqmu53IskypommauqqNMXVdH/5gRRu6JmQThXG8pZRihJM0ZYxlGeecM8Y4z1RRqFwppaqqan6w1jhYeR6lBKEgJIR9MsaZMUaXpTa6qiutdXmsLPV+v/+1nCQRDcNoGyVJejysiqKUIAHAGHP41xeenDzzIQHEugAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e65017da0a532559c8e6883b60a61e9/e4706/Windows-Self-Signed-Cert_08.avif 230w", "/en/static/0e65017da0a532559c8e6883b60a61e9/d1af7/Windows-Self-Signed-Cert_08.avif 460w", "/en/static/0e65017da0a532559c8e6883b60a61e9/ce5ae/Windows-Self-Signed-Cert_08.avif 718w"],
              "sizes": "(max-width: 718px) 100vw, 718px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e65017da0a532559c8e6883b60a61e9/a0b58/Windows-Self-Signed-Cert_08.webp 230w", "/en/static/0e65017da0a532559c8e6883b60a61e9/bc10c/Windows-Self-Signed-Cert_08.webp 460w", "/en/static/0e65017da0a532559c8e6883b60a61e9/7d0c9/Windows-Self-Signed-Cert_08.webp 718w"],
              "sizes": "(max-width: 718px) 100vw, 718px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e65017da0a532559c8e6883b60a61e9/81c8e/Windows-Self-Signed-Cert_08.png 230w", "/en/static/0e65017da0a532559c8e6883b60a61e9/08a84/Windows-Self-Signed-Cert_08.png 460w", "/en/static/0e65017da0a532559c8e6883b60a61e9/57dc1/Windows-Self-Signed-Cert_08.png 718w"],
              "sizes": "(max-width: 718px) 100vw, 718px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e65017da0a532559c8e6883b60a61e9/57dc1/Windows-Self-Signed-Cert_08.png",
              "alt": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "title": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The broker camera should show you that it is connected to the local MQTT broker `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`127.0.0.1`}</code>{` via your non-SSL port (default `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1883`}</code>{`):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Translations loaded: `}<span parentName="code" {...{
            "className": "token number"
          }}>{`266`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Initalize Mqtt
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Authenticate with Mqtt-Broker
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Connect to Mqtt-Broker `}<span parentName="code" {...{
            "className": "token number"
          }}>{`127.0`}</span>{`.0.1 on port `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1883`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Initialize Fifo-Watcher
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Warning`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` A `}<span parentName="code" {...{
            "className": "token function"
          }}>{`file`}</span>{` at the fifo filepath already exists, delete `}<span parentName="code" {...{
            "className": "token function"
          }}>{`file`}</span>{` and retry`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Start Fifo-Watcher-Thread
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Start listening`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Open fifo
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Synchronize Cgi-Server with Mqtt-Broker`}</code></pre></div>
    <p>{`And your client cameras should be connected to the IP address of your broker camera (in my case `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.117`}</code>{`) via the SSL port (default) `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8883`}</code>{` with activated TLS:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Translations loaded: `}<span parentName="code" {...{
            "className": "token number"
          }}>{`266`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Initalize Mqtt
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Authenticate with Mqtt-Broker
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Activate TLS
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Connect to Mqtt-Broker `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.117 on port `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8883`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Initialize Fifo-Watcher
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Warning`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` A `}<span parentName="code" {...{
            "className": "token function"
          }}>{`file`}</span>{` at the fifo filepath already exists, delete `}<span parentName="code" {...{
            "className": "token function"
          }}>{`file`}</span>{` and retry`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Start Fifo-Watcher-Thread
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Start listening`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Open fifo
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Synchronize Cgi-Server with Mqtt-Broker`}</code></pre></div>
    <p>{`The last entry `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`[Info] Synchronize Cgi-Server with Mqtt-Broker`}</code>{` shows you that the connection was successful and all retained MQTT Topics have been published to your MQTT broker. If this is missing something with the SSL connection went wrong.`}</p>
    <h3 {...{
      "id": "mqttfx",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#mqttfx",
        "aria-label": "mqttfx permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT.fx`}</h3>
    <p>{`You can now connect a program like `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/#mqttfx"
      }}>{`MQTT.fx`}</a>{` to your broker to try sending commands to all cameras on your MQTT network:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a058d3d5c30425645db776851d9df847/51ed8/Windows-Self-Signed-Cert_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB+0lEQVQoz52Q227TQBCG/SYoFjmQQ+WdWZ/WXtuR125oYwcaEzcKJS0S4opKFKGKa56CSkhIRMrBhSSKeD+UAyFRww2f/qvd+XZ2Rvr67fvdl7vBYDAajYbD4Xg8nkyyySTLsvv7Hz+n0+l8Pl8sFr92mM1ns/k0yzKJAOTzeVmWc7mcLMvFYqH4OJeXH1VKeabTOIrSNG0nyWmz+XRFo3HcO788O+kgoZLwfWaaR2tqNVVnoncT9m/Di49B/5PpBipFTdMIIcoKQhTL5DqaFKnEGFtZteoKQGr4se7H7mmnkVym3d7V1VWapkmSpGkaRREAECBIERElz/OqOwAhoByBUqWg1F3ef3Vxff3uvNtNkqTT6cRxLISwLAsAljKltLIDALiua3PueZ4rGuGbz/7Z68ZxEISh4zicc8uydF3HFVK5XN6VCSGGoZuG7jpOEIY644gqIQQRHc4DIeqe53BnI1f2AQCT2Yx7zOKGoQMiQQSKQOk2y5Nt512Ww1BNQc0wLZvzt0F4E7fex63b9ou/ed4+4Q55KBNCfN9/1oqjqGnbtqtpnqp6quruR6MUD8qGYViWxRgzTRNVVQF4mM23n+yz3g1Zsa6hh4L/kind3h5i0/W/ZKqq2wKpVCoX/1AoFGzbFkLAzvNbC4ii2XX/5Ye6aASBcBznN+PF33ZV6gkSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a058d3d5c30425645db776851d9df847/e4706/Windows-Self-Signed-Cert_09.avif 230w", "/en/static/a058d3d5c30425645db776851d9df847/d1af7/Windows-Self-Signed-Cert_09.avif 460w", "/en/static/a058d3d5c30425645db776851d9df847/7f308/Windows-Self-Signed-Cert_09.avif 920w", "/en/static/a058d3d5c30425645db776851d9df847/14e47/Windows-Self-Signed-Cert_09.avif 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a058d3d5c30425645db776851d9df847/a0b58/Windows-Self-Signed-Cert_09.webp 230w", "/en/static/a058d3d5c30425645db776851d9df847/bc10c/Windows-Self-Signed-Cert_09.webp 460w", "/en/static/a058d3d5c30425645db776851d9df847/966d8/Windows-Self-Signed-Cert_09.webp 920w", "/en/static/a058d3d5c30425645db776851d9df847/11b7f/Windows-Self-Signed-Cert_09.webp 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a058d3d5c30425645db776851d9df847/81c8e/Windows-Self-Signed-Cert_09.png 230w", "/en/static/a058d3d5c30425645db776851d9df847/08a84/Windows-Self-Signed-Cert_09.png 460w", "/en/static/a058d3d5c30425645db776851d9df847/c0255/Windows-Self-Signed-Cert_09.png 920w", "/en/static/a058d3d5c30425645db776851d9df847/51ed8/Windows-Self-Signed-Cert_09.png 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a058d3d5c30425645db776851d9df847/c0255/Windows-Self-Signed-Cert_09.png",
              "alt": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "title": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Note`}</strong>{` that you also have to use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`client.pem`}</code>{` file to connect to the network by SSL - the same file you uploaded to all client cameras!`}</p>
    <p>{`Subscribe to the wildcard topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`#`}</code>{` - you should see the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`retained`}</code>{` messages from all your connected cameras:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4577a07fdc50c0710b952835e17f339d/72aae/Windows-Self-Signed-Cert_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB9ElEQVQoz23S22+aUBwHcP4isHC4abk4BKEVZStVcJdk2XwxW/q07Gnpkw1eGQKHm6uCZl33Vy5obZuln4ffOcnJN7/fLznIdOJ6nucvPddduEflzVu6P0uLl8xm8/l8hhCnDZrlaO4VoFkACAAASZKAqdGidipIiiwzNIPjOACAOMJxnAKUpLJIo15vme/bXybn1kBryi29rWkaxwsUUwUkCZ4jqcNJMSxZdgLIhWnalmV1L61ez7Is27YGg4+GaZ2ZH5paS5Yb8p52dmZc9g3DaHc63U9X1ucr4/UFUlcUDEXFer3ZVAWe58+7775+C4LAXy4DP4AwhGEIQ7her/PNJs83eb7Nstm2CPN8izzMRpYoisROcEXvhBEs836wrz6EsHiyW6283S7dbncI8QwAAENRTdfjNIlgBKMojqODX7e3mwdFlnlFkZadXw4nCdxLkjiKYJqmeZ4/htN0URRxGcaPDnkMRVVdj5I43IOwnD/Lst9P/hS5//d+dXd3j/ACL4oiyzIAAEEQGJrR35jOeOw4zvhYFwt3Op2ORqObknN9/X00+uE4E4QuMQCQBEHQNE3guKSq9tt+r9fr27ZhGGypWm6EYZVKBUWxahUoCjccDv8f+6RSERuNpqpKkiQIQq1We3w9/BCCACxLcRwrCOI/8NYUvVU3XK0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4577a07fdc50c0710b952835e17f339d/e4706/Windows-Self-Signed-Cert_10.avif 230w", "/en/static/4577a07fdc50c0710b952835e17f339d/d1af7/Windows-Self-Signed-Cert_10.avif 460w", "/en/static/4577a07fdc50c0710b952835e17f339d/7f308/Windows-Self-Signed-Cert_10.avif 920w", "/en/static/4577a07fdc50c0710b952835e17f339d/3332c/Windows-Self-Signed-Cert_10.avif 964w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4577a07fdc50c0710b952835e17f339d/a0b58/Windows-Self-Signed-Cert_10.webp 230w", "/en/static/4577a07fdc50c0710b952835e17f339d/bc10c/Windows-Self-Signed-Cert_10.webp 460w", "/en/static/4577a07fdc50c0710b952835e17f339d/966d8/Windows-Self-Signed-Cert_10.webp 920w", "/en/static/4577a07fdc50c0710b952835e17f339d/6bfb6/Windows-Self-Signed-Cert_10.webp 964w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4577a07fdc50c0710b952835e17f339d/81c8e/Windows-Self-Signed-Cert_10.png 230w", "/en/static/4577a07fdc50c0710b952835e17f339d/08a84/Windows-Self-Signed-Cert_10.png 460w", "/en/static/4577a07fdc50c0710b952835e17f339d/c0255/Windows-Self-Signed-Cert_10.png 920w", "/en/static/4577a07fdc50c0710b952835e17f339d/72aae/Windows-Self-Signed-Cert_10.png 964w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4577a07fdc50c0710b952835e17f339d/c0255/Windows-Self-Signed-Cert_10.png",
              "alt": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "title": "Selbstsigniertes Windows-Zertifikat für INSTAR MQTT-Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And can send commands to your cameras via the `}<strong parentName="p">{`Publish`}</strong>{` menu.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      